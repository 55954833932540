

body{
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}


/*==============HomePage======================*/

/*----------------onderhandelingen section-------------*/
.onderhandelingen{
  background-color: $purple;
  padding: 100px 0;

  .onderhandelingen_title{
    color: $white;

    strong{
      font-weight: normal;
      color: $pink;
    }
    &:after{
      display: none;
    }
  }

  ul{
    margin-top: 150px;
    position: relative;
    li{
      font-size: $fontsize + 2;
      color: $white;
      margin-bottom: 30px;
      &:nth-child(4){
        margin-bottom:0;
      }
    }
    @media #{$desktop} {
      margin-top: 110px;
    }

    &:before{
      background-color: $pink;
      position: absolute;
      height: 538px;
      width: 5px;
      content: '';
      bottom: -310px;
      left: -40px;
      @media #{$desktop} {
        display: none;
      }
    }
  }
  @media #{$xldevice} {
    .container{
      width: 67%;
    }
  }
  @media #{$desktop} {
    .container{
      width: 970px;
    }
  }
  @media #{$tablet} {
    .container{
      width: 750px;
    }
    padding: 50px 0;
  }
  @media #{$mobile}{
    .container{
      width: 100%;
    }
    padding: 50px 0 0;
  }
}
.onderhandelingen_title{
  font-size: $fontsize + 26;
  position: relative;
  margin-top: 0;
  &:after{
    position:absolute;
    content: '';
    height: 3px;
    width: 200px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }


  @media #{$desktop} {
    font-size: $fontsize + 14;
  }
  @media #{$mobile} {
    font-size: $fontsize + 16;
  }
  @media #{$small-mobile} {
    font-size: $fontsize + 10;
  }
}

/*----------------testimonialslider section-------------*/
.testimonialslider{
  padding: 100px 0 100px;
  position: relative;

  .testimonialslider_title{
    font-size: $fontsize + 16;
    color: $purple;
    @media #{$mac} {
      font-size: $fontsize + 11;
    }
    @media #{$mac} {
      font-size: $fontsize + 8;
    }
    @media #{$mobile} {
      color: $pink;
      font-size: $fontsize + 4;
    }
  }
  .testimonialslider_subtitle{
    font-size: $fontsize + 6;
    color: $pink;
    @media #{$mac} {
      font-size: $fontsize + 3;
    }
    @media #{$mobile} {
      color: $purple;
      font-size: $fontsize;
    }
  }
  #testimonialslider{
    width: 550px;
    border: 5px solid $pink;
    position: relative;
    margin: 0 auto;
    padding: 70px 48px 100px;

    &:after{

      content: '';
      position: absolute;
      width: 5px;
      height: 360px;
      background: $pink;
      top: -360px;
      left: 20px;

      @media #{$mydesktop} {
        display: none;
      }
    }

    .carousel-control {
      top: 45%;
      height: 0;
      width: auto;
      opacity: 1;
      z-index: 9;
      img{
        @media #{$desktop} {
          width: 50px;
        }
      }
      @media #{$mobile} {
        display: none;
      }
    }
    .carousel-control.right{
      right: -100px;
      @media #{$desktop} {
        right: -80px;
      }
    }
    .carousel-control.left{
      left: -100px;
      @media #{$desktop} {
        left: -80px;
      }
    }
    .btn_Alle-getuigenissen{
      position: absolute;
      left: 0;
      bottom: 0;
      @media #{$mobile}{
        position: relative;
        margin: 60px auto 0;
        display: block;
      }
    }

    @media #{$mac}{
      width: 450px;
      padding: 50px 25px 80px;
    }
    @media #{$tablet}{
      width: 100%;
    }
    @media #{$mobile}{
      width: 100%;
      border: 0;
      padding: 0;
    }
  }
  .testimonialslider_person{
    margin: 0 auto;
    position: absolute;
    bottom: -535px;
    left: -254px;
    right: 0;
    max-height: 1000px;

    @media #{$xldevice}{
      left: -117px;
      // width: 660px;
    }
    @media #{$mac} {
      bottom: -352px;
      // width: 560px;
    }
    @media #{$desktop} {
      bottom: -352px;
      // width: 450px;
      left: 0;
    }
    @media #{$tablet} {
      bottom: -495px;
    }
  }
  @media #{$desktop} {
    padding: 160px 0 100px;
  }
  @media #{$tablet} {
    padding: 80px 0 50px;
  }
  @media #{$mobile} {
    padding: 30px 0;

    ul{
      li{
        margin-bottom: 25px;
      }
    }
  }
}
.btn_Alle-getuigenissen{
  background-color: $pink;
  font-size: $fontsize + 7;
  color: $white;
  font-weight: $font_weight + 300;
  border-radius:0;
  padding: 13px 20px;

  &:hover,&:focus{
    background-color:darken($pink, 5%);
    color: $white;
    outline: none;
  }
  @media #{$desktop} {
    font-size: $fontsize + 3;
  }
}
.transition{
  -webkit-transition:0.4s;
  -moz-transition:0.4s;
  -o-transition:0.4s;
  -ms-transition:0.4s;
}
.testimonialslider_indicator {
  bottom: 74px;
  margin-bottom: 0;
  li{
    border: 2px solid $pink;
    background-color: $white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 5px;
    &.active{
      width: 15px;
      height: 15px;
      background-color: $pink;
    }
  }
}
/*---------------------Video Section-----------*/
.video-section_image{
  width: 100%;
}
.video-section{
  position: relative;
  margin-bottom: 20px;
  .video-section_content{
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    a{
      display: inline-block;
    }
    p{
      font-size: $fontsize + 16;
      font-weight: $font_weight + 300;
      color: $white;
      margin-top: 50px;
    }
    @media #{$xldevice}{
      img{
        width: 65px;
      }
      p{
        margin-top: 30px;
      }
    }
    @media #{$desktop}{
      top: 26%;
      img{
        width: 48px;
      }
      p{
        margin-top: 20px;
        font-size: $fontsize +8;
      }
    }
    @media #{$tablet}{
      top: 18%;
      img{
        width: 40px;
      }
      p{
        margin-top: 10px;
      }
    }
  }
}

/*-----------Seminars--------------------*/
.seminars{
  background:url(../images/seminar_banner.png)no-repeat;
  background-size: cover;
  padding: 60px 0;
  background-position: 50%;

  .seminars_title{
    display: inline-block;
    color:$white;
    // @extend .onderhandelingen_title;
    margin-top: 0;
    padding-bottom: 3px;
    margin-bottom: 5px;
    border-bottom: 3px solid $white;

    &:after{
      //background-color:#EDEDED;
      // width: 100%;
      @media #{$mobile}{
        background-color: $pink;
        bottom: -11px;
      }
    }
    @media #{$mobile}{
      color: $purple;
      margin-bottom: 30px;
      display: block;
    }
  }
  .seminars_content-box{
    padding: 100px;
    h2{
      font-size: $fontsize + 12;
      color: $white;
      margin-bottom: 25px;
      margin-top: 0;
      strong{
        font-weight: $font_weight + 300;
      }
      @media #{$mobile}{
        color: $pink;
        font-size: $fontsize + 7;
      }
    }

    p{
      @extend h2;
      font-size: $fontsize + 2;
      line-height: 22px;
      @media #{$mobile}{
        color: $purple;
      }
    }

    .btn-default{
      color: $purple;
      font-size: $fontsize + 7;
      font-weight: $font_weight + 300;
      border-radius: 0;
      border:0;
      padding: 12px 24px;
      margin-top: 20px;
      @media #{$mobile}{
        width: 100%;
        white-space: normal;
        text-align: left;
      }
    }

    &:nth-child(1){
      background-color: rgba($pink, 0.7);
    }
    &:nth-child(2){
      background-color: rgba($purple, 0.7);
      margin-top: 40px;
    }
    @media #{$mac}{
      padding: 70px;
    }
    @media #{$tablet}{
      padding: 45px;
    }
    @media #{$mobile}{
      background-color: #D6D9E3 !important;
      padding: 0;
    }
  }

  @media #{$mobile}{
    padding: 40px 0;
    background-image: none !important;
    background: #D6D9E3;
  }
}

/*--------------------In de media-----------------*/
.in-de-media{
  padding: 100px 0 200px;

  .in-de-media_title{
    @extend .onderhandelingen_title;
    text-align: center;
    color: $purple;
    margin-bottom: 100px;

    &:after{
      background-color:$pink;
      bottom: -20px;
      width: 260px;
      @media #{$mobile}{
        margin: 0;
        width: 100%;
      }
    }
    @media #{$mobile}{
      text-align: left;
      margin-bottom: 70px;
    }
  }

  #in-de-media_slider{
    .carousel-control.right,.carousel-control.left{
      background-image: none;
    }
    .carousel-inner{
      width: 1024px;
      margin: 0 auto;
      @media #{$desktop}{
        width: 690px;
      }
      @media #{$tablet}{
        width: 500px;
      }
      @media #{$mobile}{
        width: 100%;
      }
    }
    .carousel-control{
      opacity: 1;
      height: 0;
      width: auto;
      top: 32%;
      @media #{$mobile}{
        display: none;
      }
    }
    .carousel-control.left{
      left: 10%;
      @media #{$xldevice}{
        left: 0;
      }
    }
    .carousel-control.right{
      right: 10%;
      @media #{$xldevice}{
        right: 0;
      }
    }
    .carousel-indicators{
      @extend .testimonialslider_indicator;
      bottom: -30px;
      left: 0;
      margin-left: 0;
      text-align: left;
    }
  }
  .in-de-media_tag{
    margin-top: 20px;
  }
  @media #{$mobile}{
    padding: 50px 0;
  }
}
.in-de-media_date{
  color: $pink;
  font-size: $fontsize + 6;
}
.in-de-media_subtitle{
  margin-top: 0;
  font-size: $fontsize + 16;
  color: $purple;

  @media #{$desktop}{
    br{
      display: none;
    }
  }
  @media #{$tablet}{
    font-size: $fontsize + 10;
  }
  @media #{$mobile}{
    font-size: $fontsize + 8;
  }
}

/*--------------------contact-----------------*/

.contact{

  .space30{
    margin-top: 30px;
  }

  padding: 50px 0;

  .contact_title{
    display: inline-block;
    color: #d26772;
    text-align: center;
    @extend .onderhandelingen_title;
    margin-top: 0;
    &:after{
      background-color:#d26772;
      bottom: 0;
      width: 100%;
      @media #{$mobile}{
        background-color: $pink;
        bottom: -11px;
      }
    }
    @media #{$mobile}{
      color: $purple;
      margin-bottom: 30px;
    }
  }

  .form-control{
    background: #d6d9e3;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #d6d9e3;
    height: 50px;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #95989a;
      font-size: $fontsize + 2;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #95989a;
      font-size: $fontsize + 2;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #95989a;
      font-size: $fontsize + 2;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #95989a;
      font-size: $fontsize + 2;
    }
  }

  textarea.form-control{
    height: auto;
    resize: none;
  }
  @media #{$mobile}{
    .btn_Alle-getuigenissen{
      width: 100%;
    }
    padding-left: 10px;
    padding-right: 10px;
  }
}


/*--------------- curriculum ------------*/

.curriculum{
  background: linear-gradient(to right,#eaecf1 0%,#eaecf1 40%, #d6d9e3 40%);
  padding: 70px 0;
  margin-bottom: 20px;

  @media #{$pc}{
    background: linear-gradient(to right,#eaecf1 0%,#eaecf1 40%, #d6d9e3 40%);
  }

  @media #{$tablet}{
    background: #eaecf1;
  }
  @media #{$mobile}{
    background: #eaecf1;
  }

  .space30{
    margin-top: 30px;
  }

  .nav-pills > li >a{
    opacity: 0.4;
    color: $purple;
    font-weight: $font-weight + 300;
    font-size: $fontsize + 4;
    padding: 10px 25px;
    margin: 12px 0;
    border-left: 3px solid transparent;

    @media #{$desktop}{
      font-size: $fontsize + 1;
    }
  }

  .curriculum_title{
    display: inline-block;
    color: #d26772;
    text-align: center;
    @extend .onderhandelingen_title;
    margin-top: 0;
    font-size: $fontsize + 20;
    &:after{
      background-color:#d26772;
      bottom: -15px;
      width: 100%;
      @media #{$mobile}{
        background-color: $pink;
        bottom: -15px;
      }
    }
    @media #{$mobile}{
      color: $purple;
      margin-bottom: 30px;
    }
  }

  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: $purple;
    background-color: transparent;
    border-left: 3px solid $purple;
    border-radius: 0;
    opacity: 1;
    position: relative;

    &:after{
      content: '';
      width: 0;
      height: 0;
      border-top: 18px solid transparent;
      border-right: 23px solid #d6d9e3;
      border-bottom: 18px solid transparent;
      position: absolute;
      right: -20%;
      top: 0;

      @media #{$pc}{
        right: -23%;
      }
      @media #{$mac}{
        right: -23%;
      }
      @media #{$mcbook}{
        right: -24%;
      }
      @media #{$desktop}{
        right: -25%;
      }

      @media #{$tablet}{
        content: none;
      }
      @media #{$mobile}{
        content: none;
      }
    }

  }
  .tab-pane h4{
    color: $purple;
    font-size: $fontsize;
  }
}


/*-------------Over Dirk Van------------*/
.over-dirk{
  padding-top: 80px;

  .over-dirk_title{
    @extend .onderhandelingen_title;
    color:$purple;

    &:after{
      background-color:$white;
      bottom: -20px;
      width: 478px;
      margin: 0;
      left: auto;
      @media #{$xldevice}{
        width: 425px;
      }
      @media #{$mac}{
        width: 325px;
      }
      @media #{$desktop}{
        width: 280px;
      }
      @media #{$tablet}{
        margin-right: auto !important;
        margin-left: 0 !important;
        left: 0 !important;
      }
      @media #{$mobile}{
        width: 100%;
        background-color:$pink;
      }
    }
    @media #{$xldevice}{

      font-size: $fontsize + 30;
    }
    @media #{$mac}{
      font-size: $fontsize + 22;
    }
    @media #{$desktop}{
      font-size: $fontsize + 16;
    }
  }
  .over-dirk_subtitle{
    @extend .in-de-media_subtitle;
    color: $pink;
    strong{
      font-weight: $font_weight + 300;
    }
    & + p{
      color:$purple;
      font-size: $fontsize + 2;
      margin-top: 30px;
      @media #{$mac}{
        br{
          display: none;
        }
      }
    }
    @media #{$desktop}{
      font-size: $fontsize + 6;
    }
  }
  .space30{
    margin-top: 30px;
  }
  .space70{
    margin-top: 70px;
  }
  .space135{
    margin-top: 135px;
    @media #{$tablet}{
      margin-top: 30px;
    }
    @media #{$mobile}{
      margin-top: 30px;
    }
  }
  @media #{$xldevice}{
    .over-dirk_img{
      margin-top: 100px;
    }
  }
  @media #{$xldevice}{
    .over-dirk_img{
      margin-top: 100px;
    }
  }
  @media #{$tablet}{
    .text-right{
      text-align: left;
    }
  }
  @media #{$mobile}{
    .text-right{
      text-align: left;
    }
    padding: 50px 0;
  }
}

.home .over-dirk{
  background-color: $purple;
  margin-bottom: 20px;

  .over-dirk_title{
    color: $white;
  }

  .over-dirk_subtitle{
    & + p{
      color: $white;
    }
  }

  @media #{$xldevice}{
    .over-dirk_img{
      margin-top: 130px;
    }
  }
  @media #{$xldevice}{
    .over-dirk_img{
      margin-top: 160px;
    }
  }
}


/*  banner Start*/

.banner{

  background-color: $purple;
  padding-top: 30px;
  .media{
    .media-left,
    .media-body{
      vertical-align: middle;

      .banner__title{
        color: $white;
        font-size: $fontsize + 30;

        @media #{$desktop}{
          font-size: 35px;
        }

        span{
          color:$pink;
          display: block;
        }

      }
      .banner__sub-title{
        color: $white;
      }
    }
  }
}


.Testimonials__title{
  color:$purple;
  font-size:34px;

  @media #{$desktop}{
    font-size: 28px;
  }

  @media #{$tablet}{
    font-size: 22px;
  }
}


.Testimonial__pad0{
  padding:100px 50px;

  @media #{$mobile}{
    padding: 30px 40px;
  }

}

/*.Testimonials__space50{
	margin-top: 100px;
}*/


.Testimonial__pad100{
  padding:8%;

  @media #{$desktop}{
    padding: 30px 41px;
  }

  @media #{$mobile}{
    padding: 30px 20px;
  }
}

.grey-color{
  background-color: #d6d9e3;
}

.Testimonials__description{
  color: $purple;
  font-size: $fontsize + 2;
}

.Testimonials__description2{
  color: $purple;
  font-size: $fontsize + 2;
  height: 150px;

  @media #{$tablet}{
    font-size:$fontsize;
  }

  @media #{$mobile}{
    height: auto;
    margin-bottom: 20px;
  }
}

.Testimonials__name{
  color: $pink;
  font-size: $fontsize + 1;
}

.Testimonials__button1{
  background-color: transparent;
  border:2px solid border-color($white);
  color: $purple;
  font-size: 21px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  border-radius: 0;
  margin-right: 20px;
  @include transition(transition,0.5s);

  &:hover{
    background-color:$white;
    color:$purple;
    border:2px solid $purple;
  }


  @media #{$tablet}{
    font-size:$fontsize + 3;
  }

  @media #{$mobile}{
    font-size:$fontsize;
  }
}

.white-border{
  border:2px solid $white;
}

.Testimonials__button2{
  background-color:$pink;
  color:$white;
  font-size: 21px;
  border-radius: 0;
  @include transition(transition,0.5s);

  &:hover{
    background-color:$white;
    color:$pink;
    border:1px solid $pink;
  }

  @media #{$tablet}{
    font-size:$fontsize + 3;
  }

  @media #{$mobile}{
    font-size:$fontsize;
  }
}


/*// Start Open Topseminarie page*/


#topseminarie, .archive-media{
  background-color: $white;
  padding: 50px 0;
  @media #{$mobile}{
    padding: 20px 0;
  }

  .open_seminarie_left{
    padding: 20px 30px;
    border: 1px solid $pink;
    // width: 100%;
    background-color: $white;
    @include transition(transition,0.5s);

    .seminarie_links{
      padding-left: 0;
      li{
        display: block;
        list-style: none;

        a{
          display: block;
          padding: 5px 10px;
          font-size: 18px;
          color: $pink;
          //
          text-decoration: none;
        }

        a.active{
          font-weight: 600;
        }

        .submenu{
          position: relative;
          width: 100%;
          top: 100%;
          z-index: 1000;
          float: none !important;
          padding: 5px 0;
          margin: 2px 0 0;
          list-style: none;
          font-size: 14px;
          text-align: left;
          background-color: $white;
          border: none;
          box-shadow: none;

          li{
            list-style: none;

            a.active::before{
              content: '';
              position: absolute;
              width: 69px;
              height: 43px;
              background: url(../images/arrow.png);
              left: -50px;
              top: 50%;
              transform: translateY(-50%);
            }


            a{
              display: block;
              padding: 5px 15px 5px 35px;
              font-size: 16px;
              color: #99a1b8;
              font-weight: 500;
              text-decoration: none;
              white-space: normal;
              position: relative;
              &:hover{
                color: $purple;
                background-color: transparent;
              }

              @media #{$tablet}{
                white-space: normal;
                padding: 5px 15px 5px 35px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    @media #{$mydesktop}{
      width:290px;
    }
    @media #{$tablet}{
      width: 220px;
      padding: 15px 15px;
    }

    @media #{$mobile}{
      width:100%;
    }
  }
  .affix{
    left: auto !important;
    top: 120px;
    @media #{$mobile}{
      top: 0 !important;
      left: 0 !important;
    }
  }
  .open_topseminarie{
    width: 100%;
    padding: 0px 0;
    @media #{$mobile}{
      padding: 20px 0;
    }

    .open_topseminarie_title{
      position: relative;
      background-color: $white;
      border-bottom: 2px solid $pink;
      margin-bottom: 20px;

      h2{
        font-size: 28px;
        color: $pink;
        line-height: 32px;
        padding: 10px 10px 10px 0;
        display: inline-block;
        margin: 0;
        width: calc(100% - 130px);

        @media #{$mobile}{
          font-size: 18px;
          line-height: 26px;
          width: 100%;
          display: block;
        }
      }
      .sign_up_btn{
        position: absolute;
        font-size: 18px;
        color: $white;
        line-height: 26px;
        padding: 10px 10px;
        right: 0;
        bottom: 0;
        display: inline-block;
        background-color: $pink;
        @include transition(transition,0.5s);
        @media #{$mobile}{
          padding: 10px 10px;
          display: block;
          width: 100%;
          text-align: center;
          position: relative;
        }
        &:hover{
          text-decoration: none;
        }
      }

    }
    .topseminarie_subtitle{
      font-size: 22px;
      color: $purple;
      font-weight: 600;
      @media #{$mobile}{
        font-size: 16px;
      }
    }
    .topseminarie_details{
      font-size: 16px;
      color: $purple;
      font-weight: 500;
      margin-top: 15px;
    }
    .list_seminarie1{
      padding-left: 20px;
      margin: 15px 0;
      li{
        font-size: 16px;
        color: $purple;
        font-weight: 500;
        padding-left: 20px;
      }
    }
    .list_seminarie2{
      padding-left: 60px;
      margin: 15px 0;
      list-style-type: circle;
      li{
        font-size: 16px;
        color: $purple;
        font-weight: 500;
        padding-left: 10px;
      }
    }

  }
}
#programma_sec{
  background: url('../images/programma_bg.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0;

  @media #{$mobile}{
    padding: 40px 0px;
  }

  .programma_title{
    font-size: 32px;
    color: $white;
    font-weight: 600;
    margin: 0;
    @media #{$mobile}{
      font-size: 22px;
    }
  }
  .programma_items{
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    margin-top: 30px;

    @media #{$tablet}{
      margin-top: 15px;
      padding: 15px;
    }

    @media #{$mobile}{
      margin-top: 20px;
      padding: 10px 5px;
    }

    .panel-default {
      background-color: transparent;
      box-shadow: none;
      border: none;
      .panel-heading {
        color: #333333;
        background-color: transparent;
        border: none;
        padding: 2px 10px;

        .panel-title{
          font-size: 18px;
          color: $purple;
          font-weight: 500;
          text-decoration: none !important;
          .collapsed{
            .arrow_r{
              transform: rotate(0deg) !important;
              -webkit-transform: rotate(0deg) !important;
              -moz-transform: rotate(0deg) !important;
            }
          }
          a{
            padding: 4px 10px;
            display: block;
            &:hover, &:focus{
              text-decoration: none !important;
              outline: none;
            }
            @media #{$mobile}{
              font-size: 16px;
            }
            .arrow_r{
              display: inline-block;
              font-size: 24px;
              font-weight: 700;
              color: $pink;
              margin-left: 15px;
              vertical-align: middle;
              transform: rotate(90deg) !important;
              -webkit-transform: rotate(90deg) !important;
              -moz-transform: rotate(90deg) !important;
            }
          }

        }
      }
      .panel-body{
        border-top: none;
        font-size: 16px;
        color: $purple;
        .list_programma{
          margin: 15px 0;
          li{
            padding: 4px 0;
          }
        }
        @media #{$mobile}{
          font-size: 14px;
        }
      }
    }
  }
}

#praktisch_sec{
  background-color: $white;
  padding: 50px 0;

  .praktisch_title{
    font-size: 32px;
    color: $purple;
    font-weight: 600;
    margin: 0;
    @media #{$mobile}{
      font-size: 22px;
    }
  }
  .praktisch_items{
    padding: 0px;
    background-color: rgba(255, 255, 255, 0.7);
    margin-top: 30px;

    @media #{$tablet}{
      margin-top: 15px;
    }

    @media #{$mobile}{
      margin-top: 0;
    }
    .panel-default {
      background-color: transparent;
      box-shadow: none;
      border: none;
      .panel-heading {
        color: #333333;
        background-color: transparent;
        border: none;
        padding: 2px 10px;

        .panel-title{
          font-size: 18px;
          color: $pink;
          font-weight: 700;
          text-decoration: none !important;
          .collapsed{
            .arrow_r{
              transform: rotate(0deg) !important;
              -webkit-transform: rotate(0deg) !important;
              -moz-transform: rotate(0deg) !important;
            }
          }
          a{
            padding: 10px 0px;
            display: block;
            border-bottom: 2px solid $pink;
            &:hover, &:focus{
              text-decoration: none !important;
              outline: none;
            }
            .arrow_r{
              display: inline-block;
              float: right;
              font-size: 22px;
              font-weight: 600;
              color: $pink;
              margin-right: 5px;
              transform: rotate(90deg) !important;
              -webkit-transform: rotate(90deg) !important;
              -moz-transform: rotate(90deg) !important;

            }

          }


        }
      }
      .panel-body{
        border-top: none;
        font-size: 16px;
        color: $purple;
        .list_programma{
          margin: 15px 0;
          li{
            padding: 4px 0;
          }
        }
        .table-striped{
          tbody {
            tr:nth-of-type(odd) {
              background-color: #ECB4B7;
            }
            td{
              font-size: 16px;
              color: $purple;
              font-weight: 600;
              padding: 10px 15px;

              @media #{$mobile}{
                font-size: 14px;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
.modal{
  background-color: rgba(51, 68, 114, 0.6);
  .modal-dialog {
    width: 50%;
    margin: 30px auto;
    @media #{$mydesktop}{
      width: 80%;
    }
    @media #{$tablet}{
      width: 90%;
    }

    @media #{$mobile}{
      width: 92%;
    }
    .modal-header {
      padding: 10px 15px;
      border-bottom: none;
      .close {
        float: right;
        font-size: 40px;
        font-weight: 500;
        color: #DD455B;
        opacity: 1;
      }
    }
    .modal-body {
      position: relative;
      padding: 15px 50px;
      @media #{$mobile}{
        padding: 15px 15px;
      }
      .modal-title{
        font-size: 32px;
        color: $pink;
        text-align: left;
        @media #{$mobile}{
          font-size: 18px;
        }
      }
      form{
        margin-top: 20px;
        padding-bottom: 50px;

        .col-sm-6{
          width :100%;
        }
        textarea.form-control{
          height: 120px;
        }
        .form-control{
          width: 100%;
          border: none;
          outline: none;
          background-color: #d6d9e3;
          border-radius: 0;
          font-size: 18px;
          color: #999;
          height: 50px;
          @media #{$mobile}{
            font-size: 16px;
            height: 40px;
          }
        }
        .send_btn{
          font-size: 18px;
          color: $white;
          padding: 15px 50px;
          background-color: $pink;
          text-align: center;
          border: none;
          @include transition(transition,0.5s);
          &:hover{
            background-color:$white;
            color: $pink;
            box-shadow: 0 0 15px 0 #999;
          }
          @media #{$mobile}{
            font-size: 16px;
            padding: 10px 20px;
          }
        }
        h4{
          font-size: 22px;
          color: #000;
          font-weight: 500;
          margin-top: 30px;
          @media #{$mobile}{
            font-size: 18px;
          }
        }
      }
    }
    .border_btm{
      border-bottom: 2px solid $pink;
      margin: 15px 0;
    }
  }
}

.modal-content{
  border-radius: 0;
}

.btn{
  border-radius: 0;
}


.banner__search{

  position: absolute;
  top: 52%;
  right: 30px;
  transform: translateY(-50%);
  display: inline-block;

  input{
    padding: 7px 30px 7px 10px;
    width: 200px;
  }

  &:after{
    content: '';
    position: absolute;
    right: 5px;
    width: 27px;
    height: 27px;
    background: url(../images/search-red.svg);
    background-size: 100%;
    display: block;
    top: 50%;
    transform: translateY(-50%);
  }

  @media #{$tablet}{
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    margin-bottom: 30px;
  }
}
