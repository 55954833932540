
/*  Header Start  */

.navbar{
  min-height: 100px;
  border-radius: 0;
  margin-bottom: 0;


}

.affix{
  // width:100%;
  z-index: 1000;
}

.navbar-default{
  background-color: $purple;
  border-color: $purple;

  .mobile-logo{
    display: none;
  }

  .navbar-header__logo{
    width: 100px;
    z-index: 99999;
  }

  .navbar-nav{

    margin-top: 50px;
    text-align: right;
    width: 85%;
    position: relative;
    float: right;

    @media #{$desktop}{
      width:85%;
    }

    @media #{$tablet}{
      width: 85%;
    }

    @media #{$mobile}{
      width:100%;
      text-align: left;
      float: none;
      margin-top: 0;
      position: inherit;
    }

    li {

      float: none;
      display: inline-block;
      margin: 0 15px;
      @media #{$desktop}{
        margin: 0 8px;
      }
      @media #{$tablet}{
        margin: 0 8px;
      }

      @media #{$mobile}{
        float:none;
        display:block;
        margin: 0 15px 5px;
      }

      a {
        color: #ffffff;
        font-size: $fontsize + 2;
        z-index: 999;
        padding: 15px 10px;
        position: relative;
        background:$purple;
        @include transition(transition,0.1s);

        &:hover, &:focus, &:active{
          color:$pink;
          background:$purple;
          box-shadow: none;
          outline: none;
        }

        @media #{$desktop}{
          font-size: $fontsize;
        }

        @media #{$mobile}{
          font-size: $fontsize * 2;
          padding: 10px 10px;
        }
      }

    }

    &:after{
      position: absolute;
      content:'';
      z-index:1;
      bottom:20px;
      width: 100%;
      height: 2px;
      background-color:$white;

      @media #{$mobile}{
        display: none;
      }
    }

    .active{
      a{
        background-color:$purple;
        color:$pink;

        &:hover{
          background-color:$purple;
          color:$pink;
        }
      }
    }

  }

  @media #{$mobile}{

    .mobile-logo{
      display: block;
      padding: 30px 10px;
      width: 140px;
    }

    .navbar-collapse{
      border: none;
      position: fixed;
      left:100%;
      display: block;
      width: 100%;
      top: 100px;
      height: 100vh !important;
      z-index: 99;
      margin-left: 0;
      background-color:$purple;
      box-shadow: none;
      @include transition(transition,0.2s);
    }

    .collapse.navbar-collapse{
      position: fixed;
      left:100%;
      display: block;
      width: 100%;
      top: 100px;
      height: 100% !important;
      z-index: 99;
      margin-left: 0;
      background-color:$purple;
      @include transition(transition,0.2s);
    }

    .collapse.navbar-collapse.in{
      position: fixed;
      left:0px;
      top: 0px;
    }

    .navbar-toggle.collapsed{

      background-color: transparent;
      border-color: transparent;


      .icon-bar{

        background-color:$pink;
        height: 5px;
        width: 30px;

        &:first-child{
          transform: rotate(0deg);
          position: relative;
          top: 0px;
          @include transition(transition,0.1s);
        }

        &:nth-child(2){
          opacity: 1;
          @include transition(transition,0.1s);
        }

        &:last-child {
          transform: rotate(0deg);
          position: relative;
          top: 0px;
          @include transition(transition,0.1s);
        }
      }
    }

    .navbar-toggle {

      background-color: transparent;
      border-color: transparent;
      z-index: 900;

      &:focus{
        background-color:transparent;
      }

      .icon-bar{

        background-color:$pink;
        height: 5px;
        width: 30px;

        &:first-child{
          transform: rotate(45deg);
          position: relative;
          top: 9px;
          @include transition(transition,0.1s);
        }
        &:nth-child(2){
          opacity: 0;
          @include transition(transition,0.1s);
        }

        &:last-child{
          transform: rotate(-45deg);
          position: relative;
          top: -9px;
          @include transition(transition,0.1s);
        }

      }
    }

  }


}



.affix.navbar{
  min-height: 70px;
  left: 20px;
  right: 20px;
  top: 0;
  width: auto;

  @media #{$tablet}{
    left: 0;
    right: 0;
  }

  .navbar-nav{
    margin-top: 10px;
  }
  .navbar-header__logo {
    display: none;
  }
}

.menu-item-has-childred{
  position: relative;
}

@media #{$mobile}{
  .navbar-default .navbar-nav .nav-dropdown{
    display: block;
  }

  .navbar-default .navbar-nav .nav-dropdown a {
    display: block;
    margin-left: 30px;
    padding: 3px;
    font-size: 24px;
  }
}

@media screen and (min-width: 768px){
  .navbar-default .navbar-nav .nav-dropdown{
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    transition: all 200ms;
    background-color: $pink;
    padding: 20px;
    width: 230px;
    z-index: 9000;

    a{
      display: inline-block;
      padding: 3px 0;
      background: none;
      border-bottom: 2px solid $pink;
      transition: all 200ms;

      &:first-of-type{
        margin-bottom: 5px;
      }

      &:hover{
        background: none;
        text-decoration: none;
        color: white;
        border-color: white;
      }
    }

    &::after{
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $pink;
      clear: both;

    }
  }

}
