.onderhandelingen_intro p{
  color: white;
}

.onderhandelingen_intro.visible-xs p{
  color: $purple;
}

.control-label,
.read-more{
  display: none;
}

.btn{
  padding: 14px 30px;
  border-radius: 0;
}

.open_topseminarie img {
  width: 100%;
  height: auto;
}

div#video-popup{
  top: 0px;
  overflow: visible;

  .modal-dialog{
    width: 80%;
    max-width: 1024px;
    margin: 30px auto;
  }
  .modal-body{
    padding: 5px;

    iframe{
      height: 100%;
      border: 0
    }
  }
}

.modal-content{

}

.single-seminar .content{
  h1, h2, h3, h4, p, li, strong{
    color: $purple;
  }
}


.archive-media{
  ul{
    padding-left: 0;
  }
  li{
    list-style: none;
    text-align: center;
    margin-bottom: 10px;
  }

  .open_seminarie_left{
    width: 100px;
  }
}

.media-title{
  color: $purple;
  display: block;
  font-size: 1.8em;
}

.media-content iframe{
  max-width: 100%;
}

.media-list-item{
  display: block;
  width: 100%;
  padding: 50px 0;
  border-bottom: 1px solid $pink;
  overflow: auto;
  display: flex;
}

.media-list-item--featured{
  padding: 30px;
  background: #D6D9E3;

  .media-featured-label{
    display: block;
    color: white;
  }
}

.media-list-item:first-of-type{
  // padding-top: 0;
}

.media-thumbnail{
  width: 200px;
  display: block;
  margin-right: 50px;
}
.media-thumbnail img{
  width: 100%;

}
.media-date{
  color: $pink;
  margin-bottom: 20px;
  display: block;
}

.media-readmore{
  font-weight: bold;
  margin-top: 10px;
  display: block;
}
.media-back{
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.archive-media .open_seminarie_left{
  a{
    position: relative;
    display: block;
  }
  a.active{
    font-weight: bold;
  }
  a.active::before{
    content: '';
    position: absolute;
    width: 69px;
    height: 43px;
    background: url(../images/arrow.png) 100% 100%;
    left: -70px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.media-featured-label{
  display: none;
}


.no-click > a{
  cursor: default;
}

.lowercase{
  text-transform: lowercase;
}

.uppercase{
  text-transform: uppercase;
}
