@import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,700|Source+Sans+Pro:300,400,700');
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "common/global";
@import "layouts/header";
@import "layouts/home";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
