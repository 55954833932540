.masterclass{
  background: $brand-light;
  position: relative;
  margin-top: 20px;

  .row{
    display: table;
  }
  .row [class*="col-"] {
    float: none;
    display: table-cell;
  }
}

.masterclass__img-column{
  display: flex;
  vertical-align: bottom;
}

.masterclass__img{
  max-width: 100%;
  align-self: flex-end;
}

.masterclass__copy{
  padding-top: 45px;
  padding-bottom: 60px;
  vertical-align: middle;
}

.masterclass__title{
  font-size: 2em;
  color: $brand-primary;
  border-bottom: 2px solid $brand-primary;
  padding-bottom: 15px;
  display: inline-block;
  margin-bottom: 30px;
}

.masterclass__subtitle{
  font-size: 1.2em;
  color: $brand-alt;
}

.masterclass__text{
  margin-bottom: 25px;
}

