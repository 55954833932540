@function border-color($bg_color) {
  @if(lighten ($bg_color) == 100){
    @return #d6d9e3;
  }@else{
    @return #ffffff;
  }
}

@mixin transition($prop, $values) {
  -moz-#{$prop}: $values;
  -ms-#{$prop}: $values;
  -o-#{$prop}: $values;
  -webkit-#{$prop}: $values;
  $prop: $values;
}

body{
  padding: 20px;

  @media #{$tablet}{
    padding :0;
  }

}

.hide{
  display: none !important;
}