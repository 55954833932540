

.footer{
  background-color: $pink;
  padding: $pad 0;
  position: relative;

  .input-group{
    @media#{$mobile}{
      display: block;
    }
  }

  .row{
    padding: 50px 0 10px;
  }

  @media#{$tablet}{
    .caldera_forms_form .first_col,
    .caldera_forms_form .last_col{
      width: 100%;
    }
  }
}

.footer__link{
  position: absolute;
  top:10px;
  right:10px;
}

.footer__address{
  color: $white;
  font-size: $fontsize + 6;
  line-height:$fontsize + 20;

  @media #{$desktop}{
    font-size: $fontsize;
    line-height:$fontsize + 10;
  }
  @media #{$mobile}{
    margin-top: 20px;
  }

}

.footer__subtitle{
  font-size: $fontsize + 16;
  margin-top: 0;
  color: $purple;

  @media #{$desktop}{
    font-size: $fontsize + 10;
  }

  @media #{$tablet}{
    font-size: $fontsize + 4;
  }
}

.footer input[type="email"]{
  height: 56px;
  border-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  font-size: $fontsize + 4;

  @media #{$tablet}{
    height:38px;
  }
}

.footer .btn{
  font-size: $fontsize + 6;
  color:$white;
  background-color: $purple;
  border-radius: 0;
  margin-left: -30px;
  border: 0;
  @include transition(transition, 0.4s);

  @media #{$tablet}{
    font-size: $fontsize + 3;
  }

  &:hover{
    background-color:$white;
    color: $purple;
    border:1px solid $purple;
  }

  @media#{$tablet}{
    width: 100%;
    margin-left: 0;
    margin-top: -10px;
  }
}